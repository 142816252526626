import React, { Component } from 'react';
import styled from '@emotion/styled';
import LazyImage from '../../components/lazy-image';

const AuthorBioHolder = styled.div`
  display: flex;
  max-width: 1080px;
  margin: 70px auto 0px;

  @media only screen and (max-width: 48em) {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    padding-bottom: 15px;
  }
`;

const AuthorImageContainerOuter = styled.div``;

const AuthorImageContainer = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 100%;
  position: relative;
  margin-right: 30px;

  @media only screen and (max-width: 48em) {
    margin-right: 0;
    margin-bottom: 20px;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100%;
    object-position: center;
  }
`;

const AuthorName = styled.div`
  font-weight: bold;
  font-style: normal;
  color: #404040;
  font-size: 20px;
  margin-bottom: 10px;
  font-family: DM Sans;
`;
const AuthorBioText = styled.div`
  font-size: 15px;
  line-height: 1.6;
`;

const AuthorInfoContainer = styled.div`
  width: 100%;
  max-width: 500px;
`;

export class AuthorBio extends Component {
  render() {
    return (
      this.props.bio && (
        <AuthorBioHolder>
          <AuthorImageContainerOuter>
            <AuthorImageContainer>
              {this.props.image && <LazyImage {...this.props.image} />}
            </AuthorImageContainer>
          </AuthorImageContainerOuter>
          <AuthorInfoContainer>
            {this.props.author && <AuthorName>{this.props.author}</AuthorName>}
            {this.props.bio.text && (
              <AuthorBioText>{this.props.bio.text}</AuthorBioText>
            )}
          </AuthorInfoContainer>
        </AuthorBioHolder>
      )
    );
  }
}

export default AuthorBio;
